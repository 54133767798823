<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ `Manage Shop` }}</h1>
        </div>
        <manage-shop-form ref="form" :manage-shop="manageShop" v-loading="loaders.fetching" :submitting="loaders.submit" @submit="formSubmit" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import ManageShopForm from "@/views/Components/SuperAdmin/ManageShop/Form";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    components: {
        BackButton,
        ManageShopForm
    },
    mixins:[generateErrorMessageMixin],
    data() {
        return {
            loaders: {
                fetching: false,
                submit : false
            },
            form: {},
            manageShop: null,
        };
    },
    mounted() {
        this.getManageShop();
    },
    methods: {
        async getManageShop() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('ManageShopModule/_getManageShop');
                let {
                    data: { data },
                } = response;
                if(data){
                    this.manageShop = data;

                }
            } catch (error) {

            }
            this.loaders.fetching = false;
        },
        formSubmit({handleSubmit,form}){
            this.form = {...form};
            handleSubmit(this.onSubmit)
        },
        async onSubmit() {
            let validation = this.$refs.form.$refs.formValidator;
            this.loaders.submit = true;
            try {
                let fd = new FormData()
                for(let index in this.form){
                    let value = this.form[index];
                    if(['status','message_status'].includes(index)){
                        fd.append(index,[1,true].includes(value) ? 1 : 0)
                    }else{
                        fd.append(index,value ? value : '');
                    }
                }
                let response = await this.$store.dispatch('ManageShopModule/_storeManageShop',{fd});
                let { data: { data, message } } = response;

                this.$notify.success({
                    title: 'Manage Shop',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$router.push({ name: 'admin.manage_shops.show' })
            } catch (error) {
                this.showErrorOnForm(error,'Manage Shop',validation);

            }
            this.loaders.submit = false;
        }
    },
};
</script>
<style scoped lang="scss"></style>
  